import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import CalendarComponent from '../components/CalendarComponent';
import CalendarComponent2 from '../components/CalendarComponent2';

function Home() {

  useEffect(() => {
    document.title = "Home | Sua Pousada";
  }, [])

  return (
    <Box>
      <Container sx={{ zIndex: 1 }}>
        <Typography variant="h3" gutterBottom>
          Bem-vindo a Pousada Cavalheiro
        </Typography>
        <CalendarComponent />
      </Container>
    </Box>
  );
}

export default Home;

import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Typography, Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BookIcon from '@mui/icons-material/Book';
import GroupIcon from '@mui/icons-material/Group';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import LogoutIcon from '@mui/icons-material/Logout';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { Link, useNavigate } from 'react-router-dom';

const NavBar = ({ setIsLoggedIn }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const pages = [
    { name: 'Home', icon: <HomeIcon />, link: '/home', color: '#FF5733' },
    { name: 'Apartamentos', icon: <ApartmentIcon />, link: '/apartamentos', color: '#3498DB' },
    { name: 'Reservas', icon: <BookIcon />, link: '/reservas', color: '#8E44AD' },
    { name: 'Hóspedes', icon: <GroupIcon />, link: '/hospedes', color: '#28B463' },
  ];

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove o token
    setIsLoggedIn(false); // Atualiza o estado de login
    navigate('/'); // Redireciona para a página de login
  };

  return (
    <Box
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      sx={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        alignItems: isExpanded ? 'flex-start' : 'center',
        height: '100vh',
        width: isExpanded ? '180px' : '50px',
        background: 'rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        borderRight: '1px solid rgba(255, 255, 255, 0.3)',
        transition: 'width 0.3s ease',
        padding: '20px 0',
        zIndex: 1000,
      }}
    >
      {pages.map((page, index) => (
        <Tooltip key={index} title={isExpanded ? '' : page.name} placement="right" arrow>
          <IconButton
            component={Link}
            to={page.link}
            sx={{
              color: page.color,
              marginBottom: '20px',
              justifyContent: isExpanded ? 'flex-start' : 'center',
              width: '100%',
              transition: 'background-color 0.3s ease',
              padding: isExpanded ? '25px 15px' : '25px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                backdropFilter: 'blur(12px)',
              },
            }}
          >
            {page.icon}
            {isExpanded && (
              <Typography
                variant="body1"
                sx={{
                  marginLeft: '10px',
                  color: 'black',
                }}
              >
                {page.name}
              </Typography>
            )}
          </IconButton>
        </Tooltip>
      ))}
      <Divider sx={{ marginY: '20px', backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
      <Tooltip title="Sair" placement="right" arrow>
        <IconButton
          onClick={handleLogout}
          sx={{
            color: '#E74C3C', // Cor do ícone de logout
            marginBottom: '20px',
            justifyContent: isExpanded ? 'flex-start' : 'center',
            width: '100%',
            transition: 'background-color 0.3s ease',
            padding: isExpanded ? '25px 15px' : '25px',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              backdropFilter: 'blur(12px)',
            },
          }}
        >
          <LogoutIcon />
          {isExpanded && (
            <Typography variant="body1" sx={{ marginLeft: '10px', color: 'black' }}>
              Sair
            </Typography>
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default NavBar;

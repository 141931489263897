import * as React from 'react';
import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, Navigate } from 'react-router-dom';
import api from '../apiConfigLogin';

const defaultTheme = createTheme();

const LoginPage = ({ setIsLoggedIn, isLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      navigate('/home');
    }
  }, [navigate, setIsLoggedIn]);

  useEffect(() => {
    document.title = "Login | Sua Pousada";
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post('/auth/login', { username, password });
      localStorage.setItem('token', response.data.token);
      setIsLoggedIn(true);
      setSuccess('Login realizado com sucesso!');
      setOpen(true);
      navigate('/home');
    } catch (error) {
      setError('Erro ao fazer login. Verifique suas credenciais.');
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {isLoggedIn ? (
        <Navigate to="/home" />
      ) : (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Entrar
            </Typography>
            <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Usuário"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <Button
                id="login"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}
              >
                Entrar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Button 
                    onClick={() => navigate('/reset-password')} 
                    variant="text" 
                    sx={{ textTransform: 'none', color: 'primary.main', '&:hover': { color: 'primary.dark' } }}
                  >
                    Esqueceu a senha?
                  </Button>
                </Grid>
                <Grid item>
                  <Button 
                    onClick={() => navigate('/register')} 
                    variant="text" 
                    sx={{ textTransform: 'none', color: 'primary.main', '&:hover': { color: 'primary.dark' } }}
                  >
                    {"Não tem uma conta? Cadastre-se"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={error ? 'error' : 'success'} sx={{ width: '100%' }}>
              {error || success}
            </Alert>
          </Snackbar>
        </Container>
      )}
    </ThemeProvider>
  );
};

export default LoginPage;

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, Modal, CircularProgress } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'date-fns/locale/pt-BR';
import { addDays, format, startOfWeek, parseISO } from 'date-fns';
import api from '../apiConfigLogin';

const statusColors = {
    cancelada: 'red',
    pendente: 'dodgerblue',
    reservada: 'yellow',
    confirmada: 'green',
    finalizada: 'grey',
};

const CalendarComponent = () => {
    const [reservas, setReservas] = useState([]);
    const [apartamentos, setApartamentos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [weekDates, setWeekDates] = useState([]);
    const [selectedReserva, setSelectedReserva] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const updateWeekDates = useCallback((date) => {
        const start = startOfWeek(date, { weekStartsOn: 1 });
        setWeekDates(Array.from({ length: 7 }, (_, i) => addDays(start, i)));
    }, []);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const [reservasResponse, apartamentosResponse] = await Promise.all([
                    api.get('/reservas/todas'),
                    api.get('/apartamentos')
                ]);
                setReservas(reservasResponse.data);
                setApartamentos(apartamentosResponse.data);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        updateWeekDates(selectedDate);
    }, [selectedDate, updateWeekDates]);

    const confirmarReserva = async () => {
        if (!selectedReserva) return;
        try {
            await api.put(`/reservas/${selectedReserva.id}`, {
                ...selectedReserva,
                status: 'confirmada',
            });
            setReservas((prev) =>
                prev.map((reserva) =>
                    reserva.id === selectedReserva.id
                        ? { ...reserva, status: 'confirmada' }
                        : reserva
                )
            );
            closeModal();
        } catch (error) {
            console.error('Erro ao confirmar reserva:', error);
        }
    };

    const finalizarReserva = async () => {
        if (!selectedReserva) return;
        try {
            await api.put(`/reservas/${selectedReserva.id}`, {
                ...selectedReserva,
                status: 'finalizada',
            });
            setReservas((prev) =>
                prev.map((reserva) =>
                    reserva.id === selectedReserva.id
                        ? { ...reserva, status: 'finalizada' }
                        : reserva
                )
            );
            closeModal();
        } catch (error) {
            console.error('Erro ao finalizar reserva:', error);
        }
    };

    const cancelarReserva = async () => {
        if (!selectedReserva) return;
        try {
            await api.put(`/reservas/${selectedReserva.id}`, {
                ...selectedReserva,
                status: 'cancelada',
            });
            setReservas((prev) =>
                prev.map((reserva) =>
                    reserva.id === selectedReserva.id
                        ? { ...reserva, status: 'cancelada' }
                        : reserva
                )
            );
            closeModal();
        } catch (error) {
            console.error('Erro ao cancelar reserva:', error);
        }
    };

    const handleWeekChange = (direction) => {
        const newDate = addDays(selectedDate, direction * 7);
        setSelectedDate(newDate);
    };

    const openReservaModal = (reserva) => {
        setSelectedReserva(reserva);
        setOpenModal(true);
    };

    const closeModal = () => setOpenModal(false);

    const isDateWithinReservation = (date, reserva) => {
        const startDate = new Date(reserva.data_checkin);
        const endDate = new Date(reserva.data_checkout);
        // A comparação é alterada para incluir o último dia
        return date >= startDate && date < addDays(endDate, 1);
    };

    const getPaperColor = (status) => {
        const colors = {
            livre: 'lightgreen',
            ocupado: 'lightcoral',
            limpeza: 'lightblue',
            manutencao: 'mediumslateblue',
        };
        return colors[status] || 'white';
    };

    const StatusLegend = () => (
        <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2, width: '100%' }}>
            {Object.entries(statusColors).map(([status, color]) => (
                <Box key={status} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: color,
                        borderRadius: '50%',
                        mr: 1,
                        border: '1px solid black'
                    }} />
                    <Typography variant="body2">{status.charAt(0).toUpperCase() + status.slice(1)}</Typography>
                </Box>
            ))}
        </Box>
    );

    const ApartamentoHeader = ({ numero, status }) => (
        <Box sx={{ textAlign: 'left', p: 1, backgroundColor: getPaperColor(status), border: '1px solid black' }}>
            <Typography variant="subtitle1">Apto {numero}</Typography>
        </Box>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5" gutterBottom>Calendário de Reservas por Apartamentos</Typography>

                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 3 }}>
                            <Button onClick={() => handleWeekChange(-1)} variant="contained">Semana Anterior</Button>
                            <Button onClick={() => handleWeekChange(1)} variant="contained">Próxima Semana</Button>
                        </Box>

                        <StatusLegend />

                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr repeat(7, 1fr)', gap: 0.2, width: '100%', borderTop: '1px solid black' }}>
                            <Box></Box>
                            {weekDates.map((date) => (
                                <Box key={date} sx={{ textAlign: 'center' }}>
                                    <Typography variant="subtitle2">{format(date, 'dd/MM')}</Typography>
                                </Box>
                            ))}

                            {apartamentos.map((apartamento) => (
                                <React.Fragment key={apartamento.id}>
                                    <ApartamentoHeader numero={apartamento.numero} status={apartamento.status} />

                                    {weekDates.map((date) => {
                                        const reservasNoDia = reservas.filter(
                                            (reserva) => reserva.apartamento_id === apartamento.id && isDateWithinReservation(date, reserva)
                                        );

                                        // Verifica se há check-out e check-in no mesmo dia
                                        const checkOutReserva = reservasNoDia.find(
                                            (reserva) => format(new Date(reserva.data_checkout), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
                                        );
                                        const checkInReserva = reservasNoDia.find(
                                            (reserva) => format(new Date(reserva.data_checkin), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
                                        );

                                        const isSplitCell = checkOutReserva && checkInReserva;

                                        return (
                                            <Box
                                                key={date}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row', // Divisão lado a lado
                                                    backgroundColor: 'transparent',
                                                    p: 0,
                                                    cursor: reservasNoDia.length > 0 ? 'pointer' : 'default',
                                                    border: '1px solid black',
                                                }}
                                            >
                                                {/* Divisão da célula ao meio para check-out e check-in */}
                                                {isSplitCell ? (
                                                    <>
                                                        {/* Metade da célula para a reserva de check-out */}
                                                        <Box
                                                            sx={{
                                                                flex: 1,
                                                                backgroundColor: statusColors[checkOutReserva.status],
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                            onClick={() => openReservaModal(checkOutReserva)}
                                                        >
                                                            <Typography variant="body2">
                                                                {checkOutReserva.hospede.nome} (Saída)
                                                            </Typography>
                                                        </Box>

                                                        {/* Metade da célula para a reserva de check-in */}
                                                        <Box
                                                            sx={{
                                                                flex: 1,
                                                                backgroundColor: statusColors[checkInReserva.status],
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                            onClick={() => openReservaModal(checkInReserva)}
                                                        >
                                                            <Typography variant="body2">
                                                                {checkInReserva.hospede.nome} (Entrada)
                                                            </Typography>
                                                        </Box>
                                                    </>
                                                ) : (
                                                    // Exibe a célula completa caso não haja check-out e check-in no mesmo dia
                                                    reservasNoDia.map((reserva, index) => (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                flex: 1,
                                                                backgroundColor: statusColors[reserva.status],
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                            onClick={() => openReservaModal(reserva)}
                                                        >
                                                            <Typography variant="body2">{reserva.hospede.nome}</Typography>
                                                        </Box>
                                                    ))
                                                )}
                                            </Box>
                                        );
                                    })}
                                </React.Fragment>
                            ))}

                        </Box>
                    </>
                )}

                <Modal open={openModal} onClose={closeModal}>
                    <Box sx={{ p: 2, backgroundColor: 'white', margin: 'auto', width: '300px', borderRadius: '8px' }}>
                        {selectedReserva && (
                            <>
                                <Typography variant="h6">Detalhes da Reserva</Typography>
                                <Typography>Hóspede: {selectedReserva.hospede.nome}</Typography>
                                <Typography>Check-in: {format(parseISO(selectedReserva.data_checkin), 'dd/MM/yyyy')}</Typography>
                                <Typography>Check-out: {format(parseISO(selectedReserva.data_checkout), 'dd/MM/yyyy')}</Typography>
                                <Typography>Adultos: {selectedReserva.adultos}</Typography>
                                <Typography>Crianças: {selectedReserva.criancas}</Typography>
                                <Typography>Status: {selectedReserva.status}</Typography>

                                {/* Botão para confirmar reserva */}
                                {['cancelada', 'finalizada', 'confirmada'].includes(selectedReserva.status) || (
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={confirmarReserva}
                                        sx={{ marginTop: 2 }}
                                    >
                                        Confirmar Reserva
                                    </Button>
                                )}

                                 {/* Botão para finalizar reserva, exibido apenas para reservas confirmadas */}
                                {selectedReserva.status === 'confirmada' && (
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={finalizarReserva}
                                        sx={{ marginTop: 2 }}
                                    >
                                        Finalizar Reserva
                                    </Button>
                                )}

                                {/* Botão para cancelar reserva, exibido apenas para reservas pendentes */}
                                {selectedReserva.status === 'pendente' && (
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={cancelarReserva}
                                        sx={{ marginTop: 2 }}
                                    >
                                        Cancelar Reserva
                                    </Button>
                                )}

                            </>
                        )}
                    </Box>
                </Modal>
            </Box>
        </LocalizationProvider>
    );
};

export default CalendarComponent;
